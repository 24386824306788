.hero {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.hero__title,
.hero__button {
  z-index: 99;
}

.hero__text {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.hero__title {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;

  text-align: center;
  letter-spacing: 0.4rem;
  color: #fff;
  font-size: 3rem;
  font-weight: bolder;
  z-index: 99;
  margin-top: 5rem;
}

.hero__subtitle {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;

  text-align: center;
  letter-spacing: 0.5rem;
  color: var(--first-color);
  font-size: 2rem;
  font-weight: bolder;
  margin: 1rem 2rem;
  z-index: 99;
}

.hero__paragraph {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  text-align: center;
  max-width: 900px;
  font-size: 1rem;
  font-weight: 400;

  color: #fff;
  letter-spacing: 3px;
  z-index: 99;
  margin-bottom: 4rem;
}

.hero__button {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 16px;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.hero__button:hover {
  background: var(--first-color);
  transition: 0.35s ease-out;
}

@media screen and (max-width: 768px) {
  .hero__paragraph {
    max-width: 300px;
  }
}
