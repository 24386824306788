.section-contact {
  text-align: center;
  background-image: url(../images/gallery-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 130px 0 50px;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-text {
  margin: 20px;
}

.section-contact .text-box {
  text-align: center;
  background-color: transparent;
  color: #fff;
  margin-top: 10px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 40px;
}

.section-contact .text-box span {
  background-color: #5ce0e6;
  color: #282e34;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 4px;
}

.section-contact .info {
  color: #fff;
  margin-bottom: 40px;
}

.section-contact .info span {
  display: block;
  font-size: 18px;
}

.section-contact .info p {
  font-size: 16px;
}

@media only screen and (max-width: 1100px) {
  .contact-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 522px) {
  .info-map {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .section-contact {
    padding-top: 155px;
  }

  .section-contact .text-box {
    font-size: 42px;
    margin-top: 50px;
  }

  .section-contact .info span {
    font-size: 22px;
  }

  .section-contact .info p {
    font-size: 18px;
  }
}
