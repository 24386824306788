/* Cards styling */
.cards__container {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  background-color: darkgrey;
}
@media screen and (max-width: 1176px) {
  .cards__container {
    flex-direction: column;
  }
  .card {
    flex-basis: auto !important;
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 25%;
  background-color: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  height: 60vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px;
  border-radius: 20px;
}
.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0; /* Start with transparent background */
  transition: opacity 0.3s ease;
  z-index: 1; /* Place the overlay above the text */
  border-radius: 20px;
}
.card:hover::before {
  opacity: 0.7; /* Fade the overlay to black on hover */
}
.card:hover {
  background-color: black;
  cursor: pointer;
  color: white;
  background-image: none;
  background-color: black; /* Change the background color to black on hover */
}
.card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  position: absolute;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  top: 50%; /* Move the element 50% down from the top */
  left: 50%; /* Move the element 50% from the left */
  transform: translate(-50%, -50%);
}
.symbol {
  margin: 0 10px;
}

.card__text {
  padding: 0 4rem;
  position: relative; /* Set the text container as a relative position */
  opacity: 0; /* Start with transparent text */
  transition: opacity 0.5s ease, transform 0.3s ease;
}
.card:hover .card__title {
  opacity: 0;
}
.card:hover .card__text {
  z-index: 2;
  opacity: 1; /* Make the text fully visible on hover */
}

.card1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(../images/card1-bg.jpg);
}

.card2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(../images/card2b-bg.jpg);
}
.card3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(../images/card3-bg.jpg);
}
.card4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(../images/card4-bg.jpg);
}
