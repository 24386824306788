/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  --first-color: #5ce0e6;
  --black-color: hsl(206, 12%, 12%);
  --card-color: hsl(0, 0%, 90%);
  --title-color: hsl(206, 12%, 12%);
  --text-color: hsl(206, 4%, 42%);
  --text-color-light: hsl(206, 4%, 75%);
  --body-color: hsl(206, 4%, 97%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Lato", sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
}

@media screen and (max-height: 545px) {
  .hero__text {
    justify-content: flex-start;
  }

  .hero__title {
    margin-top: 1rem !important;
    font-size: 2rem !important;
  }
  .hero__subtitle {
    font-size: 1rem !important;
  }
  .hero__paragraph {
    font-size: 0.8rem !important;
    margin-bottom: 1.5rem !important;
  }
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  font-weight: 400;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-inline: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 769px) {
  .section {
    padding: 7rem 0 2rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    padding-top: 0;
    flex-direction: initial;
    column-gap: 4rem;
  }
  .nav__link {
    position: relative;
    font-size: var(--normal-font-size);
    text-transform: initial;
  }

  .footer__content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-inline: auto;
  }

  .footer__content {
    grid-template-columns: repeat(4, max-content);
    column-gap: 4rem;
    padding-bottom: 5.5rem;
  }

  .footer__group {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__social {
    column-gap: 2rem;
  }

  .footer__social-link {
    font-size: 1.5rem;
  }
}
