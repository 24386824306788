.menu {
  background-image: url(../images/cards-bg.png);
  background-size: contain;

  margin-top: 80px;
}
.css-jpyrwk {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu--image {
  margin: 2rem;
  max-width: 40%;
}

@media screen and (max-width: 768px) {
  .menu {
    margin-top: 56px;
  }
  .menu--image {
    margin: 1rem;
    max-width: 80%;
  }
}
